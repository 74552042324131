import * as React from "react"
import {useEffect, useState} from "react"
import {ethers} from "ethers";
import {ToastContainer} from 'react-toastify';
import Layout from "../components/layout/Layout";
import {ConnectedWalletContext} from "../context/ConnectedWalletContext";
import {TmsTokenContext} from "../context/TmsTokenContext";
import TokenAddress from "../contract/address.json";
import TmsAbi from "../contract/tms-abi.json";
import TmsContract from "../contract/TmsContract";
import {TmsContractType} from "../contract/TmsContractType";
import 'react-toastify/dist/ReactToastify.min.css';
import "../styles/app.scss"
import {ethNetwork, rpcApiKey} from "../config/EnvConfig";
import {StateReloadContext} from "../context/StateReloadContext";
import OwnerControls from "../components/contract/OwnerControls";
import {ConnectWalletButton} from "../components/wallet/ConnectWallet";

const OwnerPage = () => {

    const [wallet, setWallet] = useState(null);
    const [provider, setProvider] = useState(null);
    const [reloadSignal, cycleReloadSignal] = useState(0);
    const [tokenWithSigner, setTokenWithSigner] = useState<TmsContractType>();

    const setConnectedWallet = (wallet: any) => setWallet(wallet);
    const setConnectedProvider = (provider: any) => setProvider(provider);
    const reloadSignalCycler = () => cycleReloadSignal(reloadSignal + 1);

    useEffect(() => {
        if (provider) {
            // @ts-ignore
            const token = new ethers.Contract(TokenAddress.tokenAddress, TmsAbi, provider.getUncheckedSigner());
            const tmsContract = new TmsContract(token);
            setTokenWithSigner(tmsContract);
        }
    }, [provider]);

    const tokenReadOnly = new ethers.Contract(TokenAddress.tokenAddress, TmsAbi,
        new ethers.providers.InfuraProvider(Number(ethNetwork()), rpcApiKey()));
    const tmsContractReadOnly = new TmsContract(tokenReadOnly);

    return (<>
            <ToastContainer/>
            <Layout>
                <StateReloadContext.Provider value={{reloadSignal, cycleReloadSignal: reloadSignalCycler}}>
                    <ConnectedWalletContext.Provider value={{
                        connectedWallet: wallet,
                        provider: provider,
                        setConnectedWallet: setConnectedWallet,
                        setProvider: setConnectedProvider
                    }}>
                        <TmsTokenContext.Provider
                            value={{tokenWithSigner: tokenWithSigner, readOnlyToken: tmsContractReadOnly}}>
                            <h1 className="is-size-1 has-text-centered neon-text">Owner Controls</h1>
                            <OwnerControls/>
                        </TmsTokenContext.Provider>
                    </ConnectedWalletContext.Provider>
                </StateReloadContext.Provider>
            </Layout>
        </>
    )
}

export default OwnerPage;
