import React, {useContext, useState} from "react";
import Layout from "../layout/Layout";
import {toast, ToastContainer} from "react-toastify";
import {ConnectWalletButton} from "../wallet/ConnectWallet";
import {TmsTokenContext} from "../../context/TmsTokenContext";
import {BigNumber} from "ethers";
import {ethers} from "ethers/lib.esm";

const OwnerControls = () => {

    const {tokenWithSigner} = useContext(TmsTokenContext);

    const [mintPrice, setMintPrice] = useState("");
    const [baseUri, setBaseUri] = useState("");
    const [reserveAddress, setReserveAddress] = useState("");
    const [reserveTokens, setReserveTokens] = useState("");

    const onMintPriceChange = (event: any) => setMintPrice(event.target.value);
    const onBaseUriChanged = (event: any) => setBaseUri(event.target.value);
    const onReserveAddressChange = (event: any) => setReserveAddress(event.target.value);
    const onReserveTokensChange = (event: any) => setReserveTokens(event.target.value);

    const transactionWrapper = async (txMethod: any): Promise<void> => {
        if (tokenWithSigner) {
            try {
                const tx = await txMethod();
                await toast.promise(tx.wait(), {
                    pending: "Waiting for Tx to Complete..",
                    success: "Tx Sucess",
                    error: "Something went wrong :("
                }, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            } catch (e: any) {
                console.log(e);
                toast.error(e.message, {position: toast.POSITION.BOTTOM_CENTER})
            }
        } else {
            toast.error("Connect wallet first");
        }
    }


    const reserveTx = () => {
        return tokenWithSigner?.reserve(reserveAddress, BigNumber.from(reserveTokens));
    }

    const toggleMintTx = () => {
        return tokenWithSigner?.toggleMint();
    }

    const setMintPriceTx = () => {
        return tokenWithSigner?.setMintPrice(ethers.utils.parseEther(mintPrice));
    }

    const setBaseUriTx = () => {
        return tokenWithSigner?.setBaseUri(baseUri);
    }

    const emergencySetStartingIndexBlockTx = () => {
        return tokenWithSigner?.emergencySetStartingIndexBlock();
    }

    const withdrawTx = () => {
        return tokenWithSigner?.withdraw();
    }

    return (
        <>

            <div className="container">
                <div className="has-text-centered mb-3">
                    <ConnectWalletButton/>
                </div>
                <div className="columns">
                    <div className="column is-one-third">
                        <div>
                            <p className="has-text-white">Address</p>
                            <input type="text" onChange={onReserveAddressChange}/>
                            <p className="has-text-white">Number of Tokens</p>
                            <input type="text" onChange={onReserveTokensChange}/>
                        </div>
                        <button
                            onClick={() => transactionWrapper(reserveTx)}>Reserve
                        </button>
                    </div>

                    <div className="column is-one-third">
                        <div>
                            <input type="text" onChange={onMintPriceChange}/>
                        </div>
                        <button onClick={() => transactionWrapper(setMintPriceTx)}>Set Mint
                            Price
                        </button>
                    </div>

                    <div className="column is-one-third">
                        <div>
                            <input type="text" onChange={onBaseUriChanged}/>
                        </div>
                        <button onClick={() => transactionWrapper(setBaseUriTx)}>Set Base
                            Uri
                        </button>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-one-third">
                        <button onClick={() => transactionWrapper(toggleMintTx)}>Toggle
                            Mint
                        </button>
                    </div>

                    <div className="column is-one-third">
                        <button
                            onClick={() => transactionWrapper(emergencySetStartingIndexBlockTx)}>Emergency Set
                            Starting Block
                        </button>
                    </div>

                    <div className="column is-one-third">
                        <button onClick={() => transactionWrapper(withdrawTx)}>Withdraw
                            Balance
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}


export default OwnerControls;